@charset "utf-8";
/* CSS Document */
html, body {margin: 0px; padding: 0px; overflow-x: hidden; background-color: #f9f9f9;}

.filture { width: 300px; left: 10px;position: absolute; z-index: 2;background:#fff; /*height:100vh;*/ padding: 8px;}
#hide-filture, #open-filture {text-decoration: none; }
.filter_overlay {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 1;
    display: none;
	transition: opacity 0.3s ease;
}
.filter_overlay.active {
	display: block; /* Display when active */
  }
  .filter-btn{
	display: none;
  }
  .search_popup_mobile{
	display: none !important;
  }
.flclass .bg-light { border-radius:0px 8px 8px 0px; padding: 8px 8px 8px 4px; height:90vh; box-shadow: 5px 0px 10px -3px #aaaaaa; border: 1px solid #ddd;}
@media screen and (max-width: 768px) {
.flclass, .block_search2 {display: none;}
.hidden-xs {display: none; }	
	
}
.modify-result {display: none; z-index: 3; position: fixed; margin-top: auto; margin-right: 10px; width: -webkit-fill-available;}
.modifydummy { text-decoration: none;}
.close-search-lg, .close-search-sm {cursor: pointer;
    float: right;
    color: #333;
    font-size: 20px;
    font-weight: 600;}
.close-search-sm { display: none;}

@media screen and (max-width: 768px) {
/***buttonclose****/
.close-search-lg {display: none !important;}
.close-search-sm {display: inline-block;}
	
}

.modify_search_overlay2 {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 1;
    display: none;
}

/**********16-august************/
.nav_logo-name {
  color: #414142;
  font-weight: 700;
  padding: .5rem 0 .5rem .5rem;
  border-bottom: 1px solid #ccc;
  width: 100%;}
.nav_logo-name span { float: right; color: #fc6604; cursor: pointer;}
.nav_logo-name p{ margin: 0px; padding: 0px; font-weight: normal;}

/********stop*******/
.nav_link {  
  padding: .5rem 0 .5rem .5rem;	
  border-bottom: 1px solid #ccc;
  width: 100%;}
.nav_link p {margin-bottom: 10px; padding: 0px; font-size: 16px; font-weight: bold;}
.nav_link .nav_stop input { height: 24px; width: 24px; text-align: center;}	

/********Airlines*******/
.nav_link .nav_Airlines ul {margin-left: 0px; padding: 0px; margin-top: 0px;}	
.nav_link .nav_Airlines ul li {	
	color: #414142;
	margin-bottom: .2rem;
	list-style: none;
	
}

/**********Departure**************/
.time_filter li {  
  margin-right: 8px;
  margin-bottom: 4px;
  float: left;
  font-size: 12px;
  border: 1px solid #ced0d4;
  border-radius: 12px;
  text-align: center;
  padding: 0 9px 9px;
  cursor: pointer;
}
.time_filter li.selected {
	border: 1px solid #006eff; background-color: azure; 
  }
.time_filter li:hover {border: 1px solid #0060df5e; background-color: azure; }

ul.time_filter li .icons.ermorning_icon {
  background: url(../../resources/images/icons/early_morning.svg) center center no-repeat;
}

ul.time_filter li .icons.morning_icon {
  background: url(../../resources/images/icons/morning.svg) center center no-repeat;
}

ul.time_filter li .icons.afternoon_icon {
  background: url(../../resources/images/icons/afternoon.svg) center center no-repeat;
}

ul.time_filter li .icons.evening_icon {
  background: url(../../resources/images/icons/evening.png) center center no-repeat;
}

.time { color: #222;}


.time_filter li .icons {
  width: 34px;
  height: 34px;
  display: block;
  margin: 0 auto;
}
	
.time_filter ul li { display: flex;}	
	
.time_filter {
  list-style: none;
  margin-right: -9px;
  overflow: hidden;
  padding-left: 0px;
  margin-bottom: 0px;
}

.roundtrip p {font-size: 17px; margin-top: 12px !important;}
.resultsearch_bg {background-color: #f0f0f0; padding: 10px; height: 112px;}

/**********result-container**start********/
.loader-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5); /* Add a semi-transparent black background for blur effect */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
  }
  
  .loader-overlay img {
	width: 60%; /* Adjust the loader size as needed */
	/* height: 70%; */
	
  }
.top-saver-deal {
  position: relative;
  top: 5px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #ffffff;
  background-color: #ff6600;
  border-radius: 0px;
  padding: 5px 10px;
  font-size: 12px;
}
.bgcolr { background-color: #f60; padding: 0 4px; color: #fff;}

.result-container {
  margin: 15px 0;
  position: relative;
}

.result-blocks {
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  box-shadow: 1px 1px 3px #dddddd, -1px -1px 3px #dddddd, 1px -1px 3px #dddddd, -1px 1px 3px #dddddd;
  border-radius: 5px 5px 0 0;
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed #cccccc;
}

.flight-detail-button {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  padding: 5px 10px;
  background-color: #ffffff;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 1px 3px #dddddd, -1px 1px 3px #dddddd;
  /*user-select: none;*/	
}

.wid-thbtn {width: 150px;float: left;}
.wid-thbtnext {width: 150px; float: right;}

/**********************/
.flight-info {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.vertical-line {
  width: 100%;
  background-color: #f3b484;
  height: 1px;
}

.flight-price-select {
  width: 15%;
}

.flight-price {
  color: #303030;
  font-size: 24px;
  text-align: right;
  font-weight: 600;
}

.flight-ppp {
  color: #505050;
  font-size: 14px;
  text-align: right;
}

.flight-ppp-price {
	color: #505050;
	font-size: 11px;
	text-align: right;
  }

.flight-price-select {
  display: flex;
  flex-direction: column;
}
/*****************************/
.flight-info-row {
  padding: 15px 0;
  margin: 0px 0 1px;  
  width: 100%;
  float: left;	
}

.flight-info-airline {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #505050;
  font-weight: 500;
}

.flight-info-daydate {
  color: #505050;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  text-align: center;
}


.flight-info-airline img {
  width: 40px;
  height: auto;
  border-radius: 5px;
  margin: 0 5px;
}

.flight-info-fromtodura {
	display: flex;
	align-items: center;
	/*width: 65%;*/
}
.flight-info-from, .flight-info-durastop, .flight-info-to, .flight-price-select {
	display: flex;
	flex-direction: column;
}
.flight-info-to {
	position: relative;
}
.flight-info-airline {
	display: flex;
	align-items: center;
	font-size: 13px;
	color: #505050;
	font-weight: 500;
	/*width: 33%;*/
}
.flight-info-airline img {
	width: 36px;
	height: auto;
	border-radius: 5px;
	margin: 0 5px;
}
.flight-info-airline span {
	max-width: 100px;
}
.airlineName {text-align: left; margin-bottom: 0px; font-size: 18px; font-weight: 600;}
.flightNumer {text-align: left; margin-bottom: 0px;}

.from-time, .to-time {
	font-size: 18px;
	color: #303030;
	font-weight: 600;
}

.from-date, .to-date {
	font-size: 13px;
	color: #303030;
	font-weight: 600;
}
.from-date {
	text-align: right;
}

.from-time span, .to-time span {
	font-size: 16px;
}
.from-time {
	text-align: right;
}
.from-city, .to-city {
	font-size: 18px;
	color: #505050;
	font-weight: 600;
}
.from-city {
	text-align: right;
}

.alternate-dates {
	color: #008a04;
	font-size: 13px;
	font-weight: 400;
}
.flight-info-durastop {
	align-items: center;
	margin: auto 20px;
	width: 65%;
}
.flight-duration, .flight-stop {
	font-size: 12px;
	color: #707070;
	margin: 3px;
}
.flight-stop-graph {
	background-color: #f3b484;
	width: 315px;
	height: 1px;
	border-radius: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.stop-dot {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #ffffff;
	border: 1px solid #f3b484;
	margin: 0 18px;
	cursor: pointer;
	position: relative;
}
.stop-tool {
	position: absolute;
	bottom: 16px;
	left: -133px;
	z-index: 1;
	padding: 5px;
	border-radius: 2px;
	color: #222;
	background-color: #fff;
	width: 280px;
	display: none;
	transition: all 0.2s ease-in-out;
	border: 1px solid #f3b484;
	box-shadow: 1px 1px 3px #dddddd, -1px -1px 3px #dddddd, 1px -1px 3px #dddddd, -1px 1px 3px #dddddd;
}
.stop-tool::after {
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	border-width: 10px;
	border-style: solid;
	border-color: #f3b484 transparent transparent transparent;
	bottom: -20px;
	left: 126.5px;
	display: none;
	transition: all 0.2s ease-in-out;
}
.stop-dot:hover .stop-tool, .stop-dot:hover .stop-tool::after {
	display: block;
}

.flight-price-select button {
  border: 0;
  color: #ffffff;
  outline: 0;
  border-radius: 5px;
  background-color: #f38216;
  width: 99%;
  height: 45px;
  font-size: 18px;
  margin-top: 10px;
  cursor: pointer;
  align-self: flex-end;
}
.fly-details {display: none;}


@media only screen and (max-width:768px) {
.flight-info-row {  
  padding: 15px 0;
}	
.flight-info-airline {  
  font-weight: 500;
  width: 100%;
  margin-bottom: 10px;
}
/*********************/	
.from-time, .to-time {
	font-size: 13px;
}
.filter-btn {
	display: block;
	margin-top: 10px;
}
.search_popup_mobile{
	display: block !important;
}
.resultsearch_bg{
	height: 0px;
}
.pricerow {
	flex-direction: row-reverse;
	margin: 0;
	width: 100%;
	padding: 0;
	margin-bottom: -10px;
}
.pricetag {
	background: #2277b2;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 10px;
	width: 30%;
	cursor: pointer;
}
.fdetails1 {
	display: flex;
	justify-content: space-between;
}
.fdetails1 img {
	width: 60px;
	height: 40px;
	margin-left: 10px;
}
.fdetails1 div {
	display: flex;
	align-items: center;
}
.ppa1 {
	display: none;
}
.ppa2 {
	font-size: 12px;
	color: #fff;
	display: block;
	text-align: center;
}
.dp {
	display: none;
}
.tp {
	color: #fff;
	text-align: center;
	font-size: 18px;
	font-weight: 500;
}
.pricerow button {
	display: none;
}
.deprow, .retrow {
	display: none;
}
.fdetails2 {
	display: none;
}
.fname {
	display: none;
}
.tadetails {
	width: 100%;
}
.flirow {
	flex-direction: column-reverse;
}
.cwdetails {
	flex-direction: row;
	width: 100%;
	margin: -10px 0;
	justify-content: center;
}
.wayd {
	display: none;
}
.durarow span:nth-child(2) {
	display: none;
}
.durarow {
	background: #fff;
	color: #101010;
	border-bottom: 1px solid #aaa;
	justify-content: center;
	margin-top: -15px;
}
.bookbtnrow {
	display: flex;
}
.horizon-filter {
	display: none;
}
.auto-fare-alert {
	width: 90%;
	text-align: center;
}
.speak-expert-section {
	flex-direction: column;
	align-items: center;
}
.speak-expert-section img {
	display: none;
}
.alert-section-form {
	flex-direction: column;
	padding: 0;
}
.alert-section-form input, .alert-section-form button {
	width: 95%;
	margin: 5px auto;
}
.auto-alert-terms {
	justify-content: center;
	margin: 5px;
}

.flight-info {
	width: 100%;
	margin-top: 60px;
}
.result-blocks {
	padding: 10px;
	border-radius: 5px 5px 0 0;
	border: 0;
}
.flight-info-airline img {
	margin: 5px;
}
.flight-info-airline {
	font-size: 12px;
}
.flight-info-airline span {
	text-align: left;
	width: 90px;
}
.flight-stop-graph {
	width: 80px;
}

.flight-detail-row {
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}
.stop-divider span {
	margin: 10px 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.flight-detail-airline {
	margin-bottom: 15px;
}
/*.super-saver-deal {
	display: none;
}*/
.flight-price-select {
	position: absolute;
	top: 0;
	display: flex;
	align-items: center;
	flex-direction: row;
	width: 95%;
	justify-content: space-between;
	border-bottom: 1px dashed #cccccc;
	padding: 5px 0 10px 0;
}
.flight-price, .flight-ppp {
	text-align: left;
}
.flight-ppp-price {
	text-align: left;
}
.flight-ppp {
	font-size: 13px;
}
.flight-ppp-price {
	font-size: 11px;
}
.flight-price {
	font-size: 18px;
}

.flight-price-select button {
  border: 0;
  color: #ffffff;
  outline: 0;
  border-radius: 5px;
  background-color: #f38216;
  width: 90%;
  height: 45px;
  font-size: 18px;
  margin-top: 10px;
  cursor: pointer;
  align-self: flex-end;
}	
	
.promo-ad button {
	padding: 5px;
}
.promo-ad span {
	padding: 5px 0 5px 10px;
}
.promo-ad span span {
	display: none;
}
.promo-ad span b {
	font-size: 13px;
}
.flight-info-airline {
	display: flex;	
	align-items: center;
	/*flex-direction: column;*/
}
.alert-screen-overlay {
	z-index: 989;
}
.matrix {
	display: none;
}
.logo-fh a {
	display: flex;
	width: 270px;
	overflow: hidden;
}

}

.nextBag {padding: 5px; width: 65px; height: 50px; float: left; border: 1px solid #ddd; text-align: left; margin: 3px; border-radius: 8px; display: flex; align-items: center;}

.baggage-dot {	
	padding: 5px; width: 73px; height: 50px; float: right; border: 1px solid #ddd; text-align: left; margin: 3px; border-radius: 8px; display: flex; align-items: center; justify-content: space-between;
}


/****flight-Details******/

.flight-detail-show {
	cursor: pointer;
	font-size: 15px;
	color: rgb(243, 130, 22);
	font-weight: 500;
}
.flight-detail-container {
	box-shadow: 0 1px 3px #dddddd, -1px 1px 3px #dddddd;
	width: 98%;
	margin: 1px 1%;
	position: relative;
	
}

.showmore-option {
	background-color: #fafafa;
	border-radius: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	display: flex;
	flex-direction: column;
	padding: 10px;
}
.show-cabin {
	background-color: #fff; 
	padding: 5px 10px; 
	border-radius: 8px; 
	border: 1px solid #ddd; 
	margin-bottom: 5px;
	
}
.show-cabin h5 { color: #f38216; font-size: 20px; }
.show-cabin h5 span { color: #f38216; font-size: 10px; }

.show-cabin button {
  border: 0;
  color: #ffffff;
  outline: 0;
  border-radius: 5px;
  background-color: #f38216;
  width: 99%;
  height: 40px;
  font-size: 17px;
  margin: 5px 0;
  cursor: pointer;
  align-self: flex-end;
}

.show-cabininr { 
	color: #505050; 
	text-align: left;font-weight: bold;
	display: flex;
	justify-content: space-between; }

.show-cabininr2 { 
	color: #505050; 
	text-align: left;
	padding: 0 0 0 10px;
	display: flex;
	justify-content: space-between;
}

.flight-detail {
	background-color: #ffffff;
	border-radius: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	display: flex;
	flex-direction: column;
	padding: 10px;
}


.flight-detail-head {
	font-size: 15px;
	font-weight: 500;
	color: #303030;
	display: flex;
	justify-content: space-between;
}
.head-depar {color: #f60; font-weight: bold;}
.head-Baggage { font-size: 12px; }
.head-Baggage p { display: block; text-align: right;  font-size: 12px; }

.flight-detail-footer {
	font-size: 15px;
	font-weight: 500;
	color: #303030;
	display: flex;
	justify-content: flex-end;
}
.flightno {font-size: 12px;}
.arrivnext {color: #f60; font-size: 12px;}

.flight-detail-row {
	display: flex;
	margin: 0px 0 15px;
	align-items: center;
	justify-content: space-between;
}

.fdhr {
  width: 100px;
  height: 2px;
  border-radius: 5px;
  background-color: #505050;
  margin: auto 2px;
}

.flight-detail-airline {
	display: flex;
	align-items: center;
}
.flight-detail-airline img {
	width: 45px;
	height: auto;
}
.airline-name-fno {
	font-size: 14px;
	color: #505050;
	font-weight: 500;
	margin: 0 5px;
}
.flight-detail-from-to {
	display: flex;
	align-items: center;
}
.flight-detail-from, .flight-detail-to {
	display: flex;
	flex-direction: column;
}
.flight-detail-arrow {
	display: flex;
}

.flight-Detailsstop-graph {
  background-color: #f3b484;
  max-width: 185px;
  width:100%;
  height: 1px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vertical-line-flights {
  width: 100%;
  background-color: #ddd;
  height: 1px;
}
/*********************/
.flight-ddinfo-durastop {
  align-items: center;
  margin: auto 20px;
  width: 39%;
}
.flight-ddinfo-durastop {
  display: flex;
  flex-direction: column;
}
/***********tooltip**********/
	

/***********tooltip*cose*********/

.mobile-respons {width: 27%; float: left; }
.mobile-respons-next {width: 70%; float: left; }

@media (max-width: 767px) {
.mobile-respons {width: 100% !important; float: left; }
.mobile-respons-next {width: 100%; float: left; }
	
.flight-ppp {display: none;}
.flight-ppp-price {display: none;}
.flight-price-select button { right: 0; width: 40%;}	
	
	
}

/**********************/
.panel-collapse { display:none;}
.panel-heading a:before {
font-family: 'Glyphicons Halflings';
content: "\e114";
float: left;
transition: all 0.5s;
}

.panel-heading.active a:before {
-webkit-transform: rotate(180deg);
-moz-transform: rotate(180deg);
transform: rotate(180deg);
}
.navbar {margin-bottom: 0px !important;}

.tooltiptest {
position:absolute;
/* display: none; */
width: 200px;
padding: 10px;
right:105px;
box-shadow: 0 0 5px rgb(160, 158, 158);
background-color: white;
color: black;
text-align: center;
border-radius: 5px;

}
.arrow {
position: absolute;
border: 1px solid #ddd;
border-width: 0 3px 3px 0;
display: inline-block;
padding: 3px;
right: -7px;
background:#fff;
top:20px;
}

.right2 {
transform: rotate(-45deg);
-webkit-transform: rotate(-45deg);
}

.carrybag { display: flex; justify-content: space-between; margin-bottom: 10px;}
.carrybag p {font-size: 12px; text-align: left;}

.filter-container {
	position: fixed;
	border-radius:0px 8px 8px 0px;
	padding: 8px 8px 8px 4px; 
	height:90vh; 
	box-shadow: 5px 0px 10px -3px #aaaaaa; 
	border: 1px solid #ddd;
	transition: opacity 0.3s ease, transform 0.3s ease;
  }

  .filter-content {
	/* position: absolute; */
	top: 0;
	/* left: 100%; Position to the right of the container */
	/* width: 300px; Adjust this width as needed */
	/* background-color: white; */
	border: 1px solid #ccc;
	/* padding: 10px; */
	/* z-index: 100; Ensure the content appears above other elements */
	width: 300px; 
	left: 10px;
	position: absolute; 
	z-index: 1;
	background:#fff; 
	/*height:100vh;*/ 
	padding: 8px;
  }
			  

